import React, { type ReactElement } from 'react';
import { Tabs } from 'antd';

type UpsertSspFormUserSyncProps = {
  sspId: number;
};

export function UpsertSspFormUserSync({ sspId }: UpsertSspFormUserSyncProps): ReactElement {
  const iframeCodeJustBidIt2xyz = `<iframe src='https://sync.justbidit2.xyz/user-sync?s=${sspId}&r=SSP_REDIR_URL' style ='display:none' width='0' height='0' frameborder='0'></iframe>`;
  const iframeCodeCreativedot2net = `<iframe src='https://sync.creativedot2.net/user-sync?s=${sspId}&r=SSP_REDIR_URL' style ='display:none' width='0' height='0' frameborder='0'></iframe>`;

  const preStyle = {
    whiteSpace: 'pre-wrap',
    border: '1px solid #ccc',
    padding: '10px',
    borderRadius: '4px',
    backgroundColor: '#f9f9f9',
    overflow: 'auto',
  };

  return (
    <>
      <Tabs defaultActiveKey="iframe">
        <Tabs.TabPane tab="Iframe" key="iframe">
          <pre style={preStyle}>{iframeCodeJustBidIt2xyz}</pre>
          <span>or</span>
          <pre style={preStyle}>{iframeCodeCreativedot2net}</pre>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Image" key="image">
          <p>
            User synchronization via image is not supported because exchange platform requires running a JavaScript
            script to sync with multiple DSPs. Using an image does not allow for the execution of JavaScript, which
            makes synchronization with multiple DSPs impossible.
          </p>
        </Tabs.TabPane>
      </Tabs>

      <div>
        <p>Where:</p>
        <p>
          <code>SSP_REDIR_URL</code> - URL-encoded redirect URL to SSP that shall include macro <code>{'{UID}'}</code>{' '}
          which will be replaced by actual userId.
        </p>
        <p>URL can also contain:</p>
        <p>
          <code>gdpr</code> - specifies if GDPR applies for current user (0 or 1)
        </p>
        <p>
          <code>gdpr_consent</code> - URL-encoded Base64-encoded consent string as per IAB specification
        </p>
        <p>
          <code>us_privacy</code> - URL-encoded U.S. Privacy string
        </p>
      </div>
    </>
  );
}
